import { observer } from "mobx-react-lite";
import archiveStore from "stores/archiveStore";
//@ts-ignore
import StacFields from '@radiantearth/stac-fields';
import { useItemContext } from './ItemContext'; // Import the context
import { Tooltip } from 'react-tooltip'
import { SmallTabContainer, MetadataTableWrapper, Actions, ActionIcon } from './infoTab.styles';
import { LoadingIcon, IconContainerStyles } from 'components/icons';
import loadingNotificationStore from 'stores/loadingNotificationStore';
import DOMPurify from "dompurify"
import { addIdToProperties } from "views/archive/helpers";
import { useCallback, useEffect, useRef, useState } from "react";
import { useStores } from "config/hooks";
import { TabsContainer } from "components/tabscontainer";
import { TabObject } from "components/tabscontainer/tabscontainer";
import archiveResultsStore from "stores/archiveResultsStore";
import { mdiEye, mdiEyeOff, mdiCheckboxBlankOffOutline, mdiCheckboxMarkedOutline, mdiShare, mdiShareAll, mdiClipboard, mdiCopyright, mdiClipboardPlay, mdiClipboardTextOutline, mdiDownloadMultiple, mdiDownload, mdiEarthBox } from '@mdi/js';
import Icon from '@mdi/react';
import ItemCard from "./ItemCard";
import { LngLatBounds } from "maplibre-gl";
import { searchCollection } from "services/archiveService";
import { triggerImageryProcessing, ProcessingRequest, getProcessingProducts } from "services/processingService";
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import 'react-virtualized/styles.css'; // Import styles for react-virtualized
import { useNavigate } from "react-router-dom";
import { ConfirmationModal } from 'components/modal/confirmationmodal';

import { Button } from "components/button";
import { differenceInHours, parseISO } from 'date-fns';

import ProgressBar from "./ProgressBar";
import { downloadFiles, downloadSingleFile, formatBytes, getAllAssetDetails, getSelectedAssetsInfo } from "./downloadHelpers"
import {
    Title, Text, ActionButtons, ModalContent
} from 'components/modal/confirmationmodal/confirmationmodal.styles';
import { ImageryProduct } from 'typings';

const handleViewMetadata = (id: string) => {
    console.log('View metadata for item with ID:', id);
    archiveStore.setActiveMetadataItem(id);
};

interface processingProduct {
    product: string;
    date: string;
}

interface OnDemandProcessingPanelParams {
    product: string;
    outcomeId?: string;
    processingProducts? : processingProduct[];
}

export const OnDemandProcessingPanel = observer((props: OnDemandProcessingPanelParams) => {
    const { product, outcomeId, processingProducts } = props;
    const [processingRequested, setprocessingRequested] = useState(false);
    const [isRequestingProcessing, setIsRequestingProcessing] = useState(false);    

    if (!outcomeId) {
        return null
    }
    // We have some inconsistencies between the product label and the names we have to request for on demand processing.
    // This mapping resolves that.
      
    const ProcessingProductsMapping: { [key: string]: string } = {
        "L1DSR": "L1D_SR"
    };

    const request: ProcessingRequest = {
        "activity_outcome_id": outcomeId,
        "product_requested": (!!ProcessingProductsMapping[product]) ? ProcessingProductsMapping[product] : product
    }

    const {
        rootStore: { userStore, notificationStore }
    } = useStores();

    const handleRequestProcessing = async () => {

        const getTokenFromStore = async () => {
            const token = await userStore.auth0Client?.getTokenSilently();
            return token;
        };

        const token = await getTokenFromStore();

        setIsRequestingProcessing(true)
        triggerImageryProcessing(
            token,
            request,
            (response: any) => {
                setIsRequestingProcessing(false)
                setprocessingRequested(true)

                // Mocks the just requested product to avoid another API request.
                const currentDate = new Date(Date.now());
                processingProducts?.push({
                    'product' : request.product_requested,
                    'date' : currentDate.toISOString()
                })
            },
            (error: any) => {
                // TODO: Handle Error somehow
                setIsRequestingProcessing(false)
                setprocessingRequested(false)
            },
        )
    };

    let processingRequestDate;
    processingProducts?.forEach(function(processingProduct : any) {
        if(processingProduct.product == request.product_requested) {
            // The requested product is present in the list of processing products.
            processingRequestDate = processingProduct.date;
            return false;
        }
    }, processingRequestDate);

    const AVERAGE_PROCESSING_TIME = 24;

    return (
            <ModalContent>

                <Title>Processing Level Unavailable</Title>

                {!!processingRequestDate ?
                    <>
                        <Text>
                            Capture {`${outcomeId}`} is being processed as {product} imagery product.
                        </Text>
                        <Text>
                            You can use <a href={`${window.location.href}`}> this link</a> and check its availability in the {product} tab.
                        </Text>
                        <br />
                    </>
                :
                    <>
                        <Text>
                            The selected processing level (<strong>{product}</strong>) is not currently available for this capture. 
                        </Text>
                        <Text>
                            You can request its generation, which typically takes up to <strong>{AVERAGE_PROCESSING_TIME} hours</strong>.
                        </Text>
                        <br />
                    </>
                }
                {( !processingRequestDate ) ?
                    <ActionButtons>
                        <Button
                            text={isRequestingProcessing ? "Requesting..." : "Request Processing"}
                            disabled={isRequestingProcessing}
                            onClick={handleRequestProcessing}
                        />
                    </ActionButtons>

                :
                    null
                }

            </ModalContent>
    );
});



interface ContractRestrictedPanelParams {
    product: string;
}

export const ContractRestrictedPanel = observer((props: ContractRestrictedPanelParams) => {
    const { product } = props;

    const {
        rootStore: { userStore, notificationStore }
    } = useStores();

    return (
        <ModalContent>
            <Title>Processing Level Unavailable</Title>
            <>
                <Text>
                    Access denied. Your contract doesn't have access to this processing
                    level (<strong>{product}</strong>).
                </Text>
                <br />
            </>
        </ModalContent>
    );
});



interface ProductInfoProps {
    product: string;
    itemsPerPage?: number;
}

export const ProductInfo = observer((props: ProductInfoProps) => {

    const abortControllerRef = useRef<AbortController | null>(null); // Store AbortController in ref

    const downloadFormRef = useRef<HTMLFormElement>(null);
    const [downloadAssets, setDownloadAssets] = useState<any | undefined>(undefined);
    const [progress, setProgress] = useState(0);
    const [totalSize, setTotalSize] = useState<number | undefined>(undefined);
    const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
    const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false);
    const [downloadButtonEnabled, setDownloadButtonEnabled] = useState<boolean>(false);
    const outcomeId = archiveStore.activeCapture?.properties['satl:outcome_id'];
    const items = archiveStore.activeProductData?.features;

    if (!outcomeId) {
        // TODO: Show a message saying the outcome ID was not found
        return null
    }
    const handleTitleClick = (id: string) => {
        const item = archiveStore.activeProductData?.features.filter(feature => feature.id == id)[0]
        const bbox = item?.bbox;

        let bounds: LngLatBounds;

        if (!!bbox) {
            bounds = new LngLatBounds(
                [bbox[0], bbox[1]], // southwest corner
                [bbox[2], bbox[3]]  // northeast corner
            );
            archiveStore.maplibreMap?.fitBounds(bounds, {
                essential: true,
                duration: 750,
                padding: 200,
            })
        }
    };

    const { areAllSelected, areAllHidden, selectAll, unselectAll, hideAll, unhideAll, selectedItems } = useItemContext();

    const rowRenderer = ({ index, key, style }: ListRowProps) => {
        if (!items || !items[index]) return null;
        const feature = items[index];
        return (
            <div key={key} style={style}>
                <ItemCard
                    feature={feature}
                    ref={(el) => archiveResultsStore.setCardRef(feature.id, el)}
                    onViewMetadata={handleViewMetadata}
                    onTitleClick={handleTitleClick}
                />
            </div>
        );
    };

    const handleOpenDownloadModalClick = async () => {

        const filteredFeatures = archiveStore.activeProductData?.features.filter(feature =>
            selectedItems.has(feature.id)
        );

        if (!filteredFeatures || filteredFeatures.length === 0) return;

        loadingNotificationStore.setText('Loading assets information...');
        loadingNotificationStore.setLoading(true);

        const assetDetails = await getAllAssetDetails(filteredFeatures);
        loadingNotificationStore.setLoading(false);

        setDownloadAssets(assetDetails);
        setShowDownloadModal(true);
    };


    // Handler for the download button click
    const handleDownload = (resetLoading?: () => void) => {
        if (resetLoading) resetLoading();
        setDownloadButtonEnabled(false);
        if (downloadFormRef.current) {
            downloadFormRef.current.requestSubmit();
        }
        //if (resetLoading) resetLoading();
    };

    const handleCancel = () => {
        if (downloadInProgress) {
            abortControllerRef.current?.abort();
        } else { // Its just closing before downloading
            setShowDownloadModal(false);
        }
    }

    // Handler for form submission
    const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();

        setDownloadInProgress(true);

        const formData = new FormData(event.currentTarget); // Get form data

        const assetsNames = Object.keys(Object.fromEntries(formData.entries()));

        let selectedAssetsInfo = getSelectedAssetsInfo(downloadAssets, assetsNames);

        let extraFiles: { name: string, input: string | Blob }[] = [];

        if (assetsNames.includes("stac_metadata")) {
            const filteredFeatures = archiveStore.activeProductData?.features.filter(feature =>
                selectedItems.has(feature.id)
            );

            filteredFeatures?.forEach(feature => {
                const metadata = JSON.stringify(feature, null, 2)
                const blob = new Blob([metadata], { type: 'application/json' });

                extraFiles.push({ name: `${feature.id}.json`, input: blob });
            }
            )
        }


        // Format the date in YYYYMMDDHHMMSS format
        const now = new Date();

        const formattedDate =
            now.getFullYear().toString() +
            (now.getMonth() + 1).toString().padStart(2, "0") + // Months are zero-based, so add 1
            now.getDate().toString().padStart(2, "0") +
            now.getHours().toString().padStart(2, "0") +
            now.getMinutes().toString().padStart(2, "0") +
            now.getSeconds().toString().padStart(2, "0");

        const fileName = `aleph_${formattedDate}.zip`;

        abortControllerRef.current = new AbortController(); // Set the controller to ref

        if (abortControllerRef.current) {
            downloadFiles(
                selectedAssetsInfo.hrefs,
                selectedAssetsInfo.names,
                extraFiles,
                fileName,
                (progress: number) => { setProgress(progress) },
                () => {
                    setDownloadInProgress(false);
                    setTimeout(() => {
                        setShowDownloadModal(false);
                        setTotalSize(undefined);
                    }, 1000);
                },
                (error: any) => {
                    if (abortControllerRef.current?.signal.aborted) {
                        console.log("The download has been cancelled by the user")
                        setDownloadInProgress(false);
                        setProgress(0);
                        setDownloadButtonEnabled(true);
                    } else {
                        console.log("Download error", error)
                        /*
                        notificationStore.add({ description: error });
                        loadingNotificationStore.setErrorMessage(
                            `There was an error during the download: ${error}`
                        );
                        */
                    }
                },
                abortControllerRef.current
            )
        }

    };

    const handleFormChanged = (event: React.FormEvent<HTMLFormElement>) => {

        const formData = new FormData(event.currentTarget); // Get form data

        const assetsNames = Object.keys(Object.fromEntries(formData.entries()));

        let selectedAssetsInfo = getSelectedAssetsInfo(downloadAssets, assetsNames);

        setTotalSize(selectedAssetsInfo.totalSize)

        setDownloadButtonEnabled(assetsNames.length > 0)
    }

    return (
        <>
            {!!archiveStore.activeProductData && archiveStore.activeProductData.features.length > 0 ? (
                <>
                    <div style={{ width: '100%', padding: '5px' }}>
                        <Tooltip id="header-tooltip" />
                        <Actions>
                            <div style={{ width: "100%" }}>
                                <div id={"firstrow"} style={{ display: 'flex', alignItems: 'center', width: "100%", paddingBottom: "5px" }}>

                                    <div style={{ width: "70%" }}>
                                        <p>Total items: {archiveStore.activeProductData.features.length} </p>
                                    </div>

                                    <div style={{ flex: "1", marginLeft: 'auto', display: 'flex', alignItems: 'flex-end', width: "100%" }}>
                                        <Actions>
                                            <Tooltip id="item-tooltip" />
                                            <ActionIcon
                                                onClick={unhideAll}
                                                data-tooltip-id="item-tooltip"
                                                data-tooltip-content="Show all">
                                                <Icon path={mdiEye} size={0.7} />
                                            </ActionIcon>

                                            <ActionIcon
                                                onClick={hideAll}
                                                data-tooltip-id="item-tooltip"
                                                data-tooltip-content="Hide all">
                                                <Icon path={mdiEyeOff} size={0.7} />
                                            </ActionIcon>

                                            <ActionIcon
                                                onClick={selectAll}
                                                data-tooltip-id="item-tooltip"
                                                data-tooltip-content="Select all">
                                                <Icon path={mdiCheckboxMarkedOutline} size={0.7} />
                                            </ActionIcon>

                                            <ActionIcon
                                                onClick={unselectAll}
                                                data-tooltip-id="item-tooltip"
                                                data-tooltip-content="Unselect all">
                                                <Icon path={mdiCheckboxBlankOffOutline} size={0.7} />
                                            </ActionIcon>
                                        </Actions>
                                    </div>
                                </div>
                                <div id={"second"} style={{ display: 'flex', alignItems: 'center', width: "100%" }}>

                                    <div style={{ width: "70%", display: 'flex', alignItems: 'flex-end', }}>
                                        <p>Selected:  </p>
                                    </div>

                                    <div style={{ marginLeft: 'auto', padding: "10px 0" }}>
                                        <Actions style={{ width: "100%" }}>

                                            <Button
                                                className='verysmall'
                                                text={`Download (${selectedItems.size.toFixed(0)})`}
                                                disabled={selectedItems.size == 0 || downloadInProgress}
                                                onClick={handleOpenDownloadModalClick}
                                                icon=<Icon path={mdiDownloadMultiple} />
                                            />


                                        </Actions>
                                    </div>
                                </div>
                                <div style={{ paddingBottom: "5px" }}>
                                    GSD: {archiveStore.activeProductData.features[0].properties["gsd"] ? archiveStore.activeProductData.features[0].properties["gsd"].toFixed(1) + "m" : "N/A"}
                                </div>
                            </div>
                        </Actions>
                    </div>
                    <div style={{ height: '100%', width: '100%' }}> {/* Set appropriate height for your list */}
                        <AutoSizer>
                            {({ height, width }) => (
                                <List
                                    ref={null} raw
                                    width={width}
                                    height={height}
                                    rowCount={!!items ? items.length : 0}
                                    rowHeight={100} // Set row height as per your design
                                    rowRenderer={rowRenderer}
                                />
                            )}
                        </AutoSizer>
                    </div>
                </>
            ) : (null)}

            {showDownloadModal &&
                <ConfirmationModal
                    isShown={showDownloadModal}
                    title={'Select assets to download'}
                    acceptText={`Download`}
                    cancelText={!!downloadInProgress ? 'Cancel download' : 'Close'}
                    onCancel={handleCancel}
                    onClose={() => { }}
                    onConfirm={(resetLoading) => handleDownload(resetLoading)}
                    confirmButtonDisabled={!downloadButtonEnabled}
                >
                    {downloadAssets && Object.keys(downloadAssets).length > 0 ?
                        <>
                            <form style={{ width: "400px" }} ref={downloadFormRef} onSubmit={handleFormSubmit} onChange={handleFormChanged}>
                                <fieldset style={{ padding: "10px" }}>

                                    <h3 style={{ padding: "0 0 10px 0" }}>Assets:</h3>

                                    {Object.keys(downloadAssets).map((key) => (
                                        <div key={key} style={{ padding: "3px" }}>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name={key}
                                                />
                                                {key} ({downloadAssets[key].length} items, {formatBytes(downloadAssets[key].reduce((sum: number, { filesize }: { filesize: number; }) => sum + filesize, 0), 0)})
                                            </label>
                                        </div>
                                    ))}

                                    <h3 style={{ padding: "10px 0 10px 0" }}>Extras:</h3>
                                    <div style={{ padding: "3px" }}>
                                        <label>
                                            <input
                                                type="checkbox"
                                                name="stac_metadata"
                                            />
                                            STAC Metadata as JSON ({selectedItems.size})
                                        </label>
                                    </div>
                                </fieldset>
                            </form>
                            <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>

                                <h1 style={{ marginTop: "20px" }}>{!!totalSize ? "Total download size: " + formatBytes(totalSize, 0) : 'Select assets to calculate total size'}</h1>

                                <div style={{ width: "100%", padding: "10px" }}>
                                    {!!downloadInProgress ?
                                        <>
                                            <h1 style={{ padding: "10px 0" }}>Downloading</h1>
                                            <ProgressBar
                                                total={totalSize}
                                                progress={progress}
                                            />
                                            <div style={{ fontSize: "14px", padding: "10px 0" }}>Download in progress...</div>
                                        </>

                                        : null
                                    }
                                </div>
                            </div>

                        </>
                        : null}
                </ConfirmationModal>
            }
            {(!!downloadAssets && Object.keys(downloadAssets).length > 0)
            }
        </>

    );
});

// Custom Hook for Product Loading
const useProductLoading = (
    activeCapture: any,
    getTokenFromStore: () => Promise<string | undefined>,
    userStore: any,
    archiveStore: any,
    notificationStore: any
) => {
    const [productTabs, setProductTabs] = useState<TabObject[]>([]);
    const [loadingProducts, setLoadingProducts] = useState(true);
    const availableCollections = new Set(userStore.activeContract.full_archive_configuration.collections);
    const ProcessingProductsArray : processingProduct[] = [];

    useEffect(() => {
      const getAllImageryProducts = (): ImageryProduct[] => [
        {
          label: 'L0',
          description: 'Raw',
          stacCollections: ['l0'],
          onDemandProcessing: false,
        },
        {
          label: 'L1A',
          description: 'Corrected raw',
          stacCollections: ['l1a'],
          onDemandProcessing: true,
        },
        {
          label: 'L1B',
          description: 'Basic Geocorrected',
          stacCollections: ['quickview-visual'],
          onDemandProcessing: false,
        },
        {
          label: 'L1C',
          description: 'Ortho-Ready',
          stacCollections: ['l1c-benchmark'],
          onDemandProcessing: true,
        },
        {
          label: 'L1D',
          description: 'Orthorectified',
          stacCollections: ['l1d'],
          onDemandProcessing: true,
        },
        {
          label: 'L1DSR',
          description: 'Ortho. Super resolved',
          stacCollections: ['l1d-sr'],
          onDemandProcessing: true,
        },
        ...(userStore.hasGaiaPermission() ? [{
          label: 'LU',
          description: 'AI Results',
          stacCollections: ['gaia', 'gaia-l1d', 'gaia-l1d-sr'],
          onDemandProcessing: true,
        }] : [])
      ];
  
      const loadRelatedProducts = async () => {
        if (!activeCapture) return;
  
        try {
          const token = await getTokenFromStore();
          const availableTabs: TabObject[] = [];

          for (const product of getAllImageryProducts()) {

            // If the product is not accessible by contract don't to load it
            // and display to the users a message about their contract limitation.
            const canAccessThisProduct = product.stacCollections.some(collection => availableCollections.has(collection))
            if (!canAccessThisProduct) {
              availableTabs.push({
                label: product.label,
                content: <ContractRestrictedPanel 
                    product={product.label} 
                />,
                enabled: false,
                tooltip: product.description,
                forbidden: true
              });
            } else {
              const outcome_id_filter = {
                op: 'and',
                args: [
                  {
                    op: '=',
                    args: [
                      { property: 'satl:outcome_id' }, 
                      activeCapture.properties["satl:outcome_id"]
                    ]
                  }
                ]
              };
              const searchParams = {
                collections: product.stacCollections,
                filter: outcome_id_filter,
                limitSearchAmount: '1000'
              };
              try {
                const data = await searchCollection(token, searchParams);
                const processingProducts : any = await getProcessingProducts(
                    token, 
                    activeCapture.properties["satl:outcome_id"]
                );

                if (processingProducts.data.length > 0) {                
                    processingProducts.data.forEach(function(product : any) {
                        ProcessingProductsArray.push({
                            product: product.product_requested,
                            date: product.created_at
                        });
                    }, ProcessingProductsArray);
                }

                if (data.features.length > 0) {
                  const processedFeatures = addIdToProperties(data);
                  archiveStore.setActiveCaptureData(product.label, processedFeatures);
                  
                  // The product is enable and has items. Show them as ProductInfo component 
                  // SHOW THE PRODUCT ITEMS AND INFO
                  availableTabs.push({
                    label: product.label,
                    content: <ProductInfo product={product.label} />,
                    enabled: true,
                    tooltip: product.description
                  });
                } else if (product.onDemandProcessing) {
                    // The product is enable but has no items. On-demand processing is available for the product.
                    // SHOW ON-DEMAND PROCESSING OPTIONS
                  availableTabs.push({
                    label: product.label,
                    content: <OnDemandProcessingPanel 
                      product={product.label} 
                      outcomeId={activeCapture.properties["satl:outcome_id"]}
                      processingProducts={ProcessingProductsArray}
                       
                    />,
                    enabled: true,
                    tooltip: product.description,
                    backorder: true
                  });
                } else {
                  availableTabs.push({
                    // The product is enable, has no items and on-demand processing is not possible. 
                    // SHOW IT AS DISABLED
                    label: product.label,
                    content: <ProductInfo product={product.label} />,
                    enabled: false,
                    tooltip: product.description
                  });
                }
              } catch (err) {
                notificationStore.add({
                  description: `Error loading ${product.label} imagery product`,
                  type: 'error'
                });
              }
            }
          }
  
          setProductTabs(availableTabs);
          setLoadingProducts(false);
        } catch (error) {
          notificationStore.add({
            description: 'Error loading related products',
            type: 'error'
          });
          setLoadingProducts(false);
        }
      };
  
      loadRelatedProducts();
    }, [activeCapture, getTokenFromStore]);

    return { productTabs, loadingProducts };
};


export const InfoTab = observer(() => {
    const { unselectAll } = useItemContext();

    const {
        rootStore: { userStore, notificationStore }
    } = useStores();

    const [selectedTab, setSelectedTab] = useState<string | undefined>(undefined);

    const getTokenFromStore = useCallback(async () => {
        const token = await userStore.auth0Client?.getTokenSilently();
        return token;
    }, [userStore]);

    // Product loading hook
    const { productTabs, loadingProducts } = useProductLoading(
        archiveStore.activeCapture,
        getTokenFromStore,
        userStore,
        archiveStore,
        notificationStore
    );

    const isTabEnabled = (label: string): boolean => {
        const tab = productTabs.find(tab => tab.label === label);
        return tab ? tab.enabled : false;
    };

    const handleTabChange = (tab: string) => {
        setSelectedTab(tab);
        unselectAll();
        if (archiveStore.activeCaptureData) {
            archiveStore.setActiveProductData(archiveStore.activeCaptureData[tab]);
        }
    };

    const showList: Array<keyof StacItemProperties> = [
        "datetime",
        "platform",
        "eo:cloud_cover",
        "satl:shadow_cover",
        "view:off_nadir",
        "view:sun_elevation",
        "view:sun_azimuth",
    ]

    const productNames: { [key: string]: string } = {
        "L1A": "L1A",
        "L0": "L0",
        "QUICKVIEW_VISUAL": "L1B",
        "QUICKVIEW_TOA": "L1B",
        "L1C": "L1C",
        "L1D": "L1D",
        "L1D_SR": "L1DSR",
        "L1D-SR": "L1DSR",
        "GAIA": "LU",
        "GAIA_L1D": "LU",
        "GAIA_L1D_SR": "LU",
    };

    const navigate = useNavigate();
    useEffect(() => {
        navigate(`/archive?outcome_id=${archiveStore.activeCapture?.properties['satl:outcome_id']}`);
    }, []);

    // Pre defined order
    useEffect(() => {
        if (isTabEnabled("L1DSR")) {
            handleTabChange("L1DSR")
        } else if (isTabEnabled("L1D")) {
            handleTabChange("L1D")
        } else if (isTabEnabled("L1B")) {
            handleTabChange("L1B")
        } else if (isTabEnabled("L1C")) {
            handleTabChange("L1C")
        }
    }, [productTabs]);


    const rows = archiveStore.activeCapture?.properties
        ? showList.map((key) => {
            if (archiveStore.activeCapture?.properties[key] !== undefined) {
                const value = archiveStore.activeCapture?.properties[key];

                StacFields.Registry.addMetadataField('satl:shadow_cover', {
                    label: "Shadow",
                    formatter: (value: number) => value + " %"
                });

                let formatted = "";
                if (typeof value === 'number') {
                    formatted = StacFields.format(value.toFixed(1), key, archiveStore.activeCapture);
                } else {
                    formatted = StacFields.format(value, key, archiveStore.activeCapture);
                }
                const label = StacFields.label(key);

                return (
                    <tr key={key}>
                        <td className="property">
                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(label) }} />
                        </td>
                        <td className="value">
                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formatted) }} />
                        </td>
                    </tr>
                );
            }
            return null;
        })
        : "";

    const handleTabHover = (tab: string, event: any) => {
        if (!isTabEnabled(tab)) {

            const reversedDict: Record<string, string> = {};

            for (const [key, value] of Object.entries(productNames)) {
                reversedDict[value] = key;
            }

            const collection: string = reversedDict[tab.toUpperCase()]?.toLowerCase()

            if (userStore.scope && !userStore.scope.includes("stac_collection:" + tab)) {
                //console.log("the user has it but there is no data" + collection)
            } else {
                //console.log("the user doesnt have the scope" + collection)
            }

        }
    }

    const outcomeId = archiveStore.activeCapture?.properties["satl:outcome_id"]
    const resultThumbnails = archiveStore.searchResultsThumbnail;
    const fullThumbnailHref = resultThumbnails?.features.find(item => item.properties["satl:outcome_id"] === outcomeId)?.assets.analytic.href

    return (
        <>
            {!!archiveStore.activeCapture && archiveStore.activeCapture?.properties ? (
                <MetadataTableWrapper>
                    <table>
                        <Tooltip id="actions-tooltip" />
                        <thead>
                            <tr>
                                <th colSpan={2}>{archiveStore.activeCapture?.properties["satl:outcome_id"]}</th>
                            </tr>
                            <tr>
                                <th>
                                    <div style={{ display: "flex" }}>
                                        <ActionIcon
                                            onClick={() => { navigator.clipboard.writeText(window.location.href) }}
                                            data-tooltip-id="actions-tooltip"
                                            data-tooltip-content="Copy shareable url to clipboard">
                                            <Icon path={mdiShareAll} size={0.7} color={"black"} />
                                        </ActionIcon>
                                        <ActionIcon
                                            onClick={() => { if (archiveStore.activeCapture?.properties["satl:outcome_id"]) navigator.clipboard.writeText(archiveStore.activeCapture?.properties["satl:outcome_id"]) }}
                                            data-tooltip-id="actions-tooltip"
                                            data-tooltip-content="Copy outcome id to clipboard">
                                            <Icon path={mdiClipboardTextOutline} size={0.7} color={"black"} />
                                        </ActionIcon>

                                        {fullThumbnailHref && <ActionIcon
                                            onClick={() => { downloadSingleFile(fullThumbnailHref) }}
                                            data-tooltip-id="actions-tooltip"
                                            data-tooltip-content="Download geolocated preview">
                                            <Icon path={mdiEarthBox} size={0.7} color={"black"} />
                                        </ActionIcon>
                                        }
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>

                </MetadataTableWrapper>
            ) : (
                <p>Please select a capture first</p>
            )}


            <SmallTabContainer>
                {
                    (!loadingProducts) ?
                        <TabsContainer
                            tabs={productTabs} selectedTab={selectedTab} setSelectedTab={handleTabChange}
                            onTabHover={handleTabHover} />
                        :
                        <div style={IconContainerStyles.centerContainer}>
                            <LoadingIcon className="loading-icon" stroke="#000" />
                        </div>
                }
            </SmallTabContainer>
        </>

    )

});

InfoTab.displayName = 'Details';
