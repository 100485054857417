import React, { useRef, useState, useReducer, useEffect } from 'react';
import { Checkbox } from 'components/checkbox';

import {
  Main,
  DropDownHeader,
  Title,
  DropDownContainer,
  DropDownList,
  ListItem,
  Helper,
  HeaderText
} from './dropdown.styles';

import { classes } from 'config/common';
import { DropdownOptions } from 'typings';
import { equalArrays } from 'config/helpers';

import { SelectArrowIcon } from 'components/icons';

export type Props = {
  /** CSS class name **/
  className?: string;
  /** Label **/
  label?: string;
  /** Dropdown options **/
  options: DropdownOptions[];
  /** Option selected function **/
  onChange?: (option: DropdownOptions[]) => void;
  /** Action **/
  action?: { text: string; icon?: any; onClick: () => void };
  /** Desired default Option **/
  defaultOptions?: DropdownOptions[];
  /** Disabled **/
  disabled?: boolean;
  /** Multiple **/
  multiple?: boolean;
  /** Auto-Select when only one option **/
  autoSelect?: boolean;
};

export const Dropdown = ({
  className,
  label,
  options,
  onChange,
  action,
  defaultOptions,
  disabled = false,
  multiple = false,
  autoSelect = false
}: Props) => {
  // Disable if no options given
  if (!options) disabled = true;

  // Handle Open/Close state
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => {
    if (!disabled) setIsOpen(!isOpen);
  };

  interface ReducerProps {
    option: DropdownOptions;
    action: string;
  }

  // Reducer function to add or remove an option
  const selectedOptionsReducer = (state: DropdownOptions[], { option, action }: ReducerProps) => {
    if (action === 'clear') {
      return [];
    } else if (multiple) {
      let newState;
      switch (action) {
        case 'add':
          if (isOptionInsideOptions(option)) {
            newState = state.some(item => item.value === option.value)
              ? [...state]
              : [...state, option];
            if (onChange) onChange(newState);
            return newState;
          } else {
            return [...state];
          }
        case 'remove':
          const update = [...state];
          newState = update.filter(item => item.value !== option.value);
          if (onChange) onChange(newState);
          return newState;
        default:
          if (onChange) onChange(state);
          return state;
      }
    } else {
      if (onChange && isOptionInsideOptions(option)) onChange([option]);
      return isOptionInsideOptions(option) ? [option] : [];
    }
  };

  const clearOptions = () => {
    setSelectedOptions({ option: { text: '', value: '' }, action: 'clear' });
  };

  const isOptionInsideOptions = (option: DropdownOptions) =>
    options?.some(dropdownOption => dropdownOption.value === option.value);

  let validDefaultOptions: DropdownOptions[] = [];
  if (defaultOptions) {
    defaultOptions.forEach(defaultOption => {
      if (isOptionInsideOptions(defaultOption)) validDefaultOptions.push(defaultOption);
    });
  }

  useEffect(() => {
    if (defaultOptions && !equalArrays(defaultOptions, selectedOptions)) {
      clearOptions();
      defaultOptions?.forEach(option => {
        setSelectedOptions({ option, action: 'add' });
      });
    }
    // eslint-disable-next-line
  }, [defaultOptions]);

  useEffect(() => {
    if (selectedOptions?.length === 0 && options?.length === 1 && autoSelect)
      updateSelectedList(options[0].value);
    // eslint-disable-next-line
  }, [options]);

  // Handle selected option state
  const [selectedOptions, setSelectedOptions] = useReducer(
    selectedOptionsReducer,
    validDefaultOptions && validDefaultOptions.length > 0 ? validDefaultOptions : []
  );

  // Handle selected options
  const updateSelectedList = (value: string) => {
    value = value.toString();
    const option = mapSelectedOption(value) || { value: '', text: '' };
    const isChecked: boolean = selectedOptions.some(option => option.value.toString() === value);
    const action = isChecked ? 'remove' : 'add';
    setSelectedOptions({ option, action });
    if (!multiple) setIsOpen(false);
  };

  const mapSelectedOption = (value: string) =>
    options.find(option => option.value.toString() === value);

  const dropdownList = options?.map((option: DropdownOptions) => {
    const checkboxListItem = (
      <Checkbox
        className="checkbox"
        name="multipleDropdownItem"
        label={option.text}
        value={option.value}
        checked={
          defaultOptions?.some(parentOption => parentOption.value === option.value) ||
          selectedOptions.some(selectedOption => selectedOption.value === option.value)
        }
        updateCheckedList={updateSelectedList}
      />
    );
    return (
      <ListItem key={option.value}>
        {multiple ? (
          checkboxListItem
        ) : (
          <div className="list-item" onClick={() => updateSelectedList(option.value)}>
            {option.text}
          </div>
        )}
      </ListItem>
    );
  });

  const actionOnClick = () => {
    action?.onClick();
    setIsOpen(false);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <Main
      ref={dropdownRef}
      className={classes(className, {
        active: isOpen,
        disabled,
        selected: !!selectedOptions[0],
        helper: !!selectedOptions[0] && !!selectedOptions[0].helper
      })}
    >
      {label && <Title onClick={toggling}>
        {disabled && <div className="disabled-background" />}
        {label}
      </Title>}
      <DropDownContainer>
        <DropDownHeader onClick={toggling}>
          <HeaderText>
            {selectedOptions.length > 1
              ? selectedOptions.map((option: DropdownOptions) => option.text).join(', ')
              : selectedOptions[0]?.text || ''}
          </HeaderText>
          <SelectArrowIcon className="chevron-icon" />
        </DropDownHeader>
        {isOpen && (
          <DropDownContainer>
            <DropDownList className={classes({ maxHeight: options.length > 7 })}>
              {dropdownList}
              {action && (
                <ListItem className="action" onClick={actionOnClick}>
                  {action.icon} {action.text}
                </ListItem>
              )}
            </DropDownList>
          </DropDownContainer>
        )}
      </DropDownContainer>
      {selectedOptions[0]?.helper && <Helper>{selectedOptions[0].helper}</Helper>}
    </Main>
  );
};

Dropdown.displayName = 'Dropdown';